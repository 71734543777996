import MainService from "@/services/MainService.js";
import dataTable from "@/components/Datatable";
// import Loader from "@/components/Loader";
window.$ = window.jQuery = require("jquery");

export default {
  name: "EvaluadorPage",
  data() {
    let ss = new MainService();
    return {
      msg: "EvaluadorPage",
      ss: ss,
      ajax: {
        url: ss.indexEvaluador(),
        headers: ss.getToken(),
      },
      columns: [
        {
          data: "id",
          name: "id",
          orderable: false,
          searchable: false,
          visible: false,
        },
        {
          data: "DT_RowIndex",
          name: "DT_RowIndex",
          title: "N",
          orderable: false,
          searchable: false,
        },
        { data: "Evaluador", name: "Evaluador", title: "Evaluador" },
        { data: "Cedula", name: "Cedula", title: "Cédula" },
        { data: "Celular", name: "Celular", title: "Celular" },
        // { data: 'UnidadAcademica', name: 'UnidadAcademica', title: 'Unidad Académica' },
        {
          data: "action",
          orderable: false,
          title: "Acciones",
          searchable: false,
        },
      ],
      evaluadores: [],
      evaluador: {},
      isLoading: false,
      errorBag: {},
    };
  },
  methods: {
    newEvaluador() {
      this.errorBag = {};
      this.evaluador = {};
      this.$refs["frm-evaluador"].show();
    },
    showEvaluador(id) {
      this.isLoading = true;
      this.ss
        .showEvaluador(id)
        .then((result) => {
          let response = result.data;
          this.evaluador = response.data;
          this.$refs["view-evaluador"].show();
          this.isLoading = false;
        })
        .catch((error) => {
          console.log(error);
          this.isLoading = false;
        });
    },
    editEvaluador() {
      this.errorBag = {};
      this.$refs["frm-evaluador"].show();
      this.$refs["view-evaluador"].hide();
    },
    cancelEvaluador() {
      if (this.evaluador.id) {
        this.$refs["view-evaluador"].show();
      }
      this.$refs["frm-evaluador"].hide();
    },
    saveEvaluador() {
      this.ss
        .storeEvaluador(this.evaluador)
        .then((result) => {
          //console.log(result);
          let response = result.data;
          this.$bvToast.toast(response.msg, {
            title: "Correcto",
            variant: "success",
            autoHideDelay: 5000,
          });
          //console.log(response);
          this.$refs["frm-evaluador"].hide();
          this.$refs["datatable-evaluador"].reload();
        })
        .catch((error) => {
          this.errorBag = error.response.data.errors;
          this.$bvToast.toast(
            "Problema al Guardar el Registro, favor verificar los Datos",
            {
              title: "Error",
              variant: "danger",
              autoHideDelay: 5000,
            }
          );
        });
    },
    deleteEvaluador() {
      this.$swal({
        title: "¿Estás seguro que deseas eliminar?",
        text: "Esta acción es irreversible!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sí, eliminar",
        cancelButtonText: "Cancelar",
      }).then((result) => {
        if (result.isConfirmed) {
          this.ss
            .destroyEvaluador(this.evaluador)
            .then((result) => {
              let response = result.data;
              //console.log(response);
              this.$swal({
                title: "¡Eliminado!",
                text: "El evaluador ha sido eliminado.",
                icon: "success",
              });
              this.$refs["view-evaluador"].hide();
              this.$refs["datatable-evaluador"].reload();
            })
            .catch((error) => {
              console.log(error);
            });
        }
      });
    },
    draw() {
      window.$(".btn-datatable-Evaluador").on("click", (evt) => {
        const data = window.$(evt.target)[0].id;
        this.showEvaluador(data);
      });
    },
  },
  components: {
    dataTable,
    // Loader
  },
  mounted() {
    var persona = JSON.parse(localStorage.getItem("persona"));
    if (!persona) {
      this.$router.push("/Login");
    }
  },
};
