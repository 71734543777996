<template>
  <form>
    <md-card>
      <md-card-header :data-background-color="dataBackgroundColor">
        <h4 class="title">Editar Perfil</h4>
        <p class="category">Completa tu perfil</p>
      </md-card-header>

      <md-card-content>
        <div class="md-layout">
          <div class="md-layout-item md-small-size-100 md-size-50">
            <md-field>
              <label>Nombres</label>
              <md-input v-model="auth.Nombres" type="text"></md-input>
            </md-field>
            <span
              class="error"
              style="position: relative; top: -20px"
              v-if="errorBag.Nombres"
              >{{ errorBag.Nombres }}</span
            >
          </div>

          <div class="md-layout-item md-small-size-100 md-size-50">
            <md-field>
              <label>Apellido Materno</label>
              <md-input v-model="auth.ApellidoMaterno" type="text"></md-input>
            </md-field>
            <span
              class="error"
              style="position: relative; top: -20px"
              v-if="errorBag.ApellidoMaterno"
              >{{ errorBag.ApellidoMaterno }}</span
            >
          </div>

          <div class="md-layout-item md-small-size-100 md-size-50">
            <md-field>
              <label>Apellido Paterno</label>
              <md-input v-model="auth.ApellidoPaterno" type="text"></md-input>
            </md-field>
            <span
              class="error"
              style="position: relative; top: -20px"
              v-if="errorBag.ApellidoPaterno"
              >{{ errorBag.ApellidoPaterno }}</span
            >
          </div>

          <div class="md-layout-item md-small-size-100 md-size-50">
            <md-field>
              <label>Correo</label>
              <md-input v-model="auth.email" type="email"></md-input>
            </md-field>
            <span
              class="error"
              style="position: relative; top: -20px"
              v-if="errorBag.email"
              >{{ errorBag.email }}</span
            >
          </div>

          <div class="md-layout-item md-small-size-100 md-size-50">
            <md-field>
              <label>Contraseña</label>
              <md-input v-model="auth.password" type="password"></md-input>
            </md-field>
            <span
              class="error"
              style="position: relative; top: -20px"
              v-if="errorBag.password"
              >{{ errorBag.password }}</span
            >
          </div>

          <div
            class="md-layout-item md-small-size-100 md-size-50"
            v-if="auth.Rol == 2"
          >
            <md-field>
              <label>Grado</label>
              <md-input v-model="auth.Grado" type="text"></md-input>
            </md-field>
            <span
              class="error"
              style="position: relative; top: -20px"
              v-if="errorBag.Grado"
              >{{ errorBag.Grado }}</span
            >
          </div>

          <div class="md-layout-item md-size-100 text-right">
            <button @click.prevent="savePersona()" class="btn btn-success">
              <i class="fa fa-save"></i>
              Guardar
            </button>
            >
          </div>
        </div>
      </md-card-content>
    </md-card>
  </form>
</template>
<script>
import MainService from "@/services/MainService.js";
export default {
  name: "edit-profile-form",
  props: {
    dataBackgroundColor: {
      type: String,
      default: "",
    },
  },
  data() {
    let ss = new MainService();
    return {
      msg: "edit-profile-form",
      ss: ss,
      ajax: {
        headers: ss.getToken(),
      },
      auth: {
        Grado: null,
        unidadacademica: null,
      },
      username: null,
      disabled: null,
      emailadress: null,
      lastname: null,
      firstname: null,
      address: null,
      city: null,
      country: null,
      code: null,
      aboutme: "ESCUELA MILITAR DE INGENIERIA",
      isLoading: false,
      errorBag: {},
      roles: null,
      unidadesacademicas: null,
    };
  },
  methods: {
    savePersona() {
      this.auth.unidadacademica = this.auth.UnidadAcademica;
      console.log(this.auth);
      this.ss
        .storePersona(this.auth)
        .then((result) => {
          console.log(result);
          let response = result.data;
          this.$bvToast.toast(response.msg, {
            title: "Correcto",
            variant: "success",
            autoHideDelay: 5000,
          });
          console.log(response);
        })
        .catch((error) => {
          this.errorBag = error.response.data.errors;
          this.$bvToast.toast(
            "Problema al Guardar el Registro, favor verificar los Datos",
            {
              title: "Error",
              variant: "danger",
              autoHideDelay: 5000,
            }
          );
        });
    },
  },
  mounted() {
    this.auth = JSON.parse(localStorage.getItem("persona"));
    if (!this.auth) {
      this.$router.push("/Login");
    }
  },
};
</script>
<style></style>
