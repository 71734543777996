<template>
  <div class="content fondocardsmenu ">
  
    <div class="">
     
         <div class=" ">


          <div class=" row ">



            <article class="card__article col-lg-6">
               <img src="@/assets/img/landscape-1.png" alt="image" class="card__img">

               <div class="card__data">
                  <!-- <span class="card__description">Vancouver Mountains, Canada</span> -->
                  <h2 class="card__title">REPOSITORIO DE GRADO</h2>
                  <span class="card__description">Realiza tu proceso de inscripcion</span>
                  <a href="#" class="card__button" style="color: #448aff;">Inscribirme</a>
               </div>
            </article>

            <article class="card__article col-lg-6">
               <img src="@/assets/img/landscape-2.png" alt="image" class="card__img">

               <div class="card__data">
                  <h2 class="card__title">REPOSITORIO DE POSGRADO</h2>
                  <span class="card__description">Realiza tu seguimiento academico</span>
                  <a href="#" class="card__button" style="color: #448aff;">Ver mis notas</a>
               </div>
            </article>

            <article class="card__article col-lg-6">
               <img src="@/assets/img/landscape-3.png" alt="image" class="card__img">

               <div class="card__data">
                  <h2 class="card__title">BIBLIOTECA</h2>
                  <span class="card__description">Manten actualizado tu file personal</span>

                  <a href="#" class="card__button" style="color: #448aff;">Actualizar documentos</a>
               </div>
            </article>

             <article class="card__article col-lg-6">
               <img src="@/assets/img/landscape-3.png" alt="image" class="card__img">

               <div class="card__data">
                  <h2 class="card__title">SISTEMA DE GESTION DE TRABAJOS DE GRADO</h2>
                  <span class="card__description">Incribete y descarga certificados de los diferentes eventos</span>

                  <a href="#" class="card__button" style="color: #448aff;">Ver mis cursos</a>
               </div>
            </article>

          </div>
          
         </div>
      </div>
  </div>
</template>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500&display=swap");

/*=============== VARIABLES CSS ===============*/


/*========== Responsive typography ==========*/
@media screen and (min-width: 1120px) {
  :root {
    --h2-font-size: 1.5rem;
    --small-font-size: .875rem;
  }
}

/*=============== BASE ===============*/
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}



img {
  display: block;
  max-width: 100%;
  height: auto;
}


.fondocardsmenu{
  /* background: #000; */
 
  /* background-image: url('../../assets/img/sidebar-2.png') ; */


}

.showcase {
  position: relative;
  background-size: cover;
}

.bg-image {
  opacity: 0.7;
  /* height: 100vh;
  weight: 100%; */
}

.bg-img-title {
  position: absolute;
  top: 120px;
  /* left: 20px; */
}

/*=============== CARD ===============*/
.container {
  display: grid;
  place-items: center;
  margin-inline: 1.5rem;
  padding-block: 5rem;
  /* justify-content: center; */
}

.card__container {
  display: grid;
  row-gap: 3.5rem;
  
  /* justify-content: center; */
  /* display: flex; */

}

.card__article {
  position: relative;
  overflow: hidden;
  margin-bottom: 50px;
  justify-content: center;
  display: flex;
}

.card__img {
  width: 328px;
  border-radius: 1.5rem;
}

.card__data {
  width: 280px;
  color: white;
  /* background-color: var(--container-color); */
  /* background: #448aff; */
  background: #ebbb28;
  padding: 1.5rem 2rem;
  /* box-shadow: 0 8px 24px hsla(0, 0%, 0%, .15); */
  /* box-shadow: 100 8px 24px hsla(0, 0%, 0%, .15); */
  border-radius: 1rem;
  position: absolute;
  bottom: -9rem;
  left: 0;
  right: 0;
  margin-inline: auto;
  opacity: 0;
  transition: opacity 1s 1s;
}

.card__description {
  display: block;
  font-size: var(--small-font-size);
  margin-bottom: .25rem;
}

.card__title {
  font-size: var(--h2-font-size);
  font-weight: 500;
  color: var(--title-color);
  margin-bottom: .75rem;
}

.card__button {
  text-decoration: none;
  font-size: var(--small-font-size);
  font-weight: 500;
  color: var(--first-color);
}

.card__button:hover {
  text-decoration: underline;

}

/* Naming animations in hover */
.card__article:hover .card__data {
  animation: show-data 1s forwards;
  opacity: 1;
  transition: opacity .3s;
}

.card__article:hover {
  animation: remove-overflow 2s forwards;
}

.card__article:not(:hover) {
  animation: show-overflow 2s forwards;
}

.card__article:not(:hover) .card__data {
  animation: remove-data 1s forwards;
}

/* Card animation */
@keyframes show-data {
  50% {
    transform: translateY(-10rem);
  }
  100% {
    transform: translateY(-7rem);
  }
}

@keyframes remove-overflow {
  to {
    overflow: initial;
  }
}

@keyframes remove-data {
  0% {
    transform: translateY(-7rem);
  }
  50% {
    transform: translateY(-10rem);
  }
  100% {
    transform: translateY(.5rem);
  }
}

@keyframes show-overflow {
  0% {
    overflow: initial;
    pointer-events: none;
  }
  50% {
    overflow: hidden;
  }
}

/*=============== BREAKPOINTS ===============*/
/* For small devices */
@media screen and (max-width: 340px) {
  .container {
    margin-inline: 1rem;
  }

  .card__data {
    width: 250px;
    padding: 1rem;
  }
}

/* For medium devices */
@media screen and (min-width: 768px) {
  .card__container {
    grid-template-columns: repeat(2, 1fr);
    column-gap: 1.5rem;
  }
}

/* For large devices */
@media screen and (min-width: 1120px) {
  .container {
    height: 100vh;
  }

  .card__container {
    grid-template-columns: repeat(3, 1fr);
  }
  .card__img {
    width: 348px;
  }
  .card__data {
    width: 316px;
    padding-inline: 2.5rem;
  }
}
</style>
<script>
// import {
//   ChartCard,
// } from "@/components";

export default {
  components: {
    
    // ChartCard,
  },
  data() {
    return {

      dailySalesChart: {
        data: {
          labels: ["M", "T", "W", "T", "F", "S", "S"],
          series: [[12, 17, 7, 17, 23, 18, 38]],
        },
        options: {
          lineSmooth: this.$Chartist.Interpolation.cardinal({
            tension: 0,
          }),
          low: 0,
          high: 50, // creative tim: we recommend you to set the high sa the biggest value + something for a better look
          chartPadding: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
          },
        },
      },
      dataCompletedTasksChart: {
        data: {
          labels: ["12am", "3pm", "6pm", "9pm", "12pm", "3am", "6am", "9am"],
          series: [[230, 750, 450, 300, 280, 240, 200, 190]],
        },

        options: {
          lineSmooth: this.$Chartist.Interpolation.cardinal({
            tension: 0,
          }),
          low: 0,
          high: 1000, // creative tim: we recommend you to set the high sa the biggest value + something for a better look
          chartPadding: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
          },
        },
      },
      emailsSubscriptionChart: {
        data: {
          labels: [
            "Ja",
            "Fe",
            "Ma",
            "Ap",
            "Mai",
            "Ju",
            "Jul",
            "Au",
            "Se",
            "Oc",
            "No",
            "De",
          ],
          series: [
            [542, 443, 320, 780, 553, 453, 326, 434, 568, 610, 756, 895],
          ],
        },
        options: {
          axisX: {
            showGrid: false,
          },
          low: 0,
          high: 1000,
          chartPadding: {
            top: 0,
            right: 5,
            bottom: 0,
            left: 0,
          },
        },
        responsiveOptions: [
          [
            "screen and (max-width: 640px)",
            {
              seriesBarDistance: 5,
              axisX: {
                labelInterpolationFnc: function (value) {
                  return value[0];
                },
              },
            },
          ],
        ],
      },
    };
  },
};
</script>
