import MainService from "@/services/MainService.js";
import dataTable from "@/components/Datatable";
// import Loader from "@/components/Loader";
window.$ = window.jQuery = require("jquery");

export default {
  name: "UnidadAcademicaPage",
  data() {
    let ss = new MainService();
    return {
      msg: "UnidadAcademicaPage",
      ss: ss,
      ajax: {
        url: ss.indexUnidadAcademica(),
        headers: ss.getToken(),
      },
      columns: [
        {
          data: "id",
          name: "id",
          orderable: false,
          searchable: false,
          visible: false,
        },
        {
          data: "DT_RowIndex",
          name: "DT_RowIndex",
          title: "N",
          orderable: false,
          searchable: false,
        },
        {
          data: "UnidadAcademica",
          name: "UnidadAcademica",
          title: "Nombre de Unidad Academica",
        },
        { data: "Sigla", name: "Sigla", title: "Sigla" },
        { data: "Alias", name: "Alias", title: "Lugar" },
        {
          data: "action",
          orderable: false,
          title: "Acciones",
          searchable: false,
        },
      ],
      unidadesAcademicas: [],
      unidadAcademica: {},
      isLoading: false,
      errorBag: {},
    };
  },
  methods: {
    newUnidadAcademica() {
      this.errorBag = {};
      this.unidadAcademica = {};
      this.$refs["frm-unidad-academica"].show();
    },
    showUnidadAcademica(id) {
      this.isLoading = true;
      this.ss
        .showUnidadAcademica(id)
        .then((result) => {
          let response = result.data;
          this.unidadAcademica = response.data;
          this.$refs["view-unidad-academica"].show();
          this.isLoading = false;
        })
        .catch((error) => {
          console.log(error);
          this.isLoading = false;
        });
    },
    editUnidadAcademica() {
      this.errorBag = {};
      this.$refs["frm-unidad-academica"].show();
      this.$refs["view-unidad-academica"].hide();
    },
    cancelUnidadAcademica() {
      if (this.unidadAcademica.id) {
        this.$refs["view-unidad-academica"].show();
      }
      this.$refs["frm-unidad-academica"].hide();
    },
    saveUnidadAcademica() {
      this.ss
        .storeUnidadAcademica(this.unidadAcademica)
        .then((result) => {
          //console.log(result);
          let response = result.data;
          this.$bvToast.toast(response.msg, {
            title: "Correcto",
            variant: "success",
            autoHideDelay: 5000,
          });
          this.$refs["frm-unidad-academica"].hide();
          this.$refs["datatable-unidad-academica"].reload();
        })
        .catch((error) => {
          this.errorBag = error.response.data.errors;
          this.$bvToast.toast(
            "Problema al Guardar el Registro, favor verificar los Datos",
            {
              title: "Error",
              variant: "danger",
              autoHideDelay: 5000,
            }
          );
        });
    },
    deleteUnidadAcademica() {
      this.$swal({
        title: "¿Estás seguro que deseas eliminar?",
        text: "Esta acción es irreversible!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sí, eliminar",
        cancelButtonText: "Cancelar",
      }).then((result) => {
        if (result.isConfirmed) {
          this.ss
            .destroyUnidadAcademica(this.unidadAcademica)
            .then((result) => {
              let response = result.data;
              //console.log(response);
              this.$swal({
                title: "¡Eliminado!",
                text: "La unidad académica ha sido eliminada.",
                icon: "success",
              });
              this.$refs["view-unidad-academica"].hide();
              this.$refs["datatable-unidad-academica"].reload();
            })
            .catch((error) => {
              console.log(error);
            });
        }
      });
    },

    draw() {
      window.$(".btn-datatable-UnidadAcademica").on("click", (evt) => {
        const data = window.$(evt.target)[0].id;
        this.showUnidadAcademica(data);
      });
    },
  },
  components: {
    dataTable,
    // Loader
  },
  mounted() {
    var persona = JSON.parse(localStorage.getItem("persona"));
    if (!persona) {
      this.$router.push("/Login");
    }
  },
};
