<template>
  <div>
    <!-- <div class="text-center" v-if="$route.meta.login" style="background: #25272e;height: 100vh;width: 100%;position: absolute;"> -->
    <!-- <div class="text-center" v-if="$route.meta.login" style="">
    
  </div> -->

    <div class="wrapper" :class="{ 'nav-open': $sidebar.showSidebar }">
      <notifications></notifications>

      <side-bar
        :sidebar-item-color="sidebarBackground"
        :sidebar-background-image="sidebarBackgroundImage"
        v-if="!$route.meta.sinmenu"
      >
        <!-- <mobile-menu slot="content"></mobile-menu> -->

        <!-- 
      <sidebar-link to="/repositorios">
        <md-icon>assignment</md-icon>
        <p>Repositorios </p>
      </sidebar-link> -->
        <!-- 
        <sidebar-link to="/dashboard">
          <md-icon>dashboard</md-icon>
          <p>Dashboard</p>
        </sidebar-link> -->

        <sidebar-link to="/unidadacademica" v-if="showAdministrador">
          <md-icon>school</md-icon>
          <p>Unidades Académicas</p>
        </sidebar-link>

        <sidebar-link to="/idioma" v-if="showAdministrador">
          <md-icon>language</md-icon>
          <p>Idiomas</p>
        </sidebar-link>

        <sidebar-link to="/persona" v-if="showAdministrador || showCoordinador">
          <md-icon>person</md-icon>
          <p>Usuarios</p>
        </sidebar-link>

        <sidebar-link to="/rol" v-if="showAdministrador">
          <md-icon>security</md-icon>
          <p>Roles</p>
        </sidebar-link>

        <sidebar-link to="/carrera" v-if="showCoordinador">
          <md-icon>work</md-icon>
          <p>Carreras</p>
        </sidebar-link>

        <sidebar-link to="/evaluador" v-if="showCoordinador">
          <md-icon>thumb_up</md-icon>
          <p>Evaluadores</p>
        </sidebar-link>

        <sidebar-link to="/institucion" v-if="showCoordinador">
          <md-icon>business</md-icon>
          <p>Instituciónes</p>
        </sidebar-link>

        <div
          @click="showCertificados = !showCertificados"
          v-if="showCoordinador"
        >
          <sidebar-link to="#">
            <md-icon>school</md-icon>
            <p>Registro de certificado</p>
          </sidebar-link>
        </div>
        <sidebar-link
          to="/createinst"
          v-show="showCertificados && showCoordinador"
        >
          <md-icon>school</md-icon>
          <p>Certificado convenio</p>
        </sidebar-link>
        <sidebar-link
          to="/createidiag"
          v-show="showCertificados && showCoordinador"
        >
          <md-icon>school</md-icon>
          <p>Certificado Diag. Convenio</p>
        </sidebar-link>
        <sidebar-link
          to="/createprog"
          v-show="showCertificados && showCoordinador"
        >
          <md-icon>school</md-icon>
          <p>Certificado Programa</p>
        </sidebar-link>
        <sidebar-link
          to="/creatediag"
          v-show="showCertificados && showCoordinador"
        >
          <md-icon>school</md-icon>
          <p>Certificado Diagnostico</p>
        </sidebar-link>
        <sidebar-link
          to="/createconv"
          v-show="showCertificados && showCoordinador"
        >
          <md-icon>school</md-icon>
          <p>Planilla revalidacion</p>
        </sidebar-link>

        <sidebar-link
          to="/createsuple"
          v-show="showCertificados && showCoordinador"
        >
          <md-icon>school</md-icon>
          <p>Certificado supletorio</p>
        </sidebar-link>

        <sidebar-link to="/alumno" v-if="showCoordinador || showJefeSecre">
          <md-icon>assignment</md-icon>
          <p>Certificados Emitidos</p>
        </sidebar-link>

        <sidebar-link to="/newex" v-if="showCoordinador || showJefeSecre">
          <md-icon>table_view</md-icon>
          <p>Exportar</p>
        </sidebar-link>
      </side-bar>

      <div class="main-panel" v-if="!$route.meta.login">
        <top-navbar v-if="!$route.meta.sinheader"></top-navbar>

        <!-- <fixed-plugin
        :color.sync="sidebarBackground"
        :image.sync="sidebarBackgroundImage"
      >
      </fixed-plugin> -->

        <dashboard-content v-if="!$route.meta.login"> </dashboard-content>
        <login-content v-if="$route.meta.login"> </login-content>

        <content-footer v-if="!$route.meta.hideFooter"></content-footer>
      </div>

      <div class="" v-if="$route.meta.login">
        <top-navbar v-if="!$route.meta.sinheader"></top-navbar>

        <!-- <dashboard-content v-if="!$route.meta.login"> </dashboard-content> -->
        <login-content v-if="$route.meta.login"> </login-content>

        <content-footer v-if="!$route.meta.hideFooter"></content-footer>
      </div>
    </div>
  </div>
</template>
<script>
import TopNavbar from "./TopNavbar.vue";
import ContentFooter from "./ContentFooter.vue";
import DashboardContent from "./Content.vue";
import LoginContent from "./ContentLogin.vue";

export default {
  components: {
    TopNavbar,
    DashboardContent,
    ContentFooter,
    LoginContent,
  },
  data() {
    return {
      sidebarBackground: "green",
      sidebarBackgroundImage: require("@/assets/img/sidebar-2.png"),
      showAdministrador: false,
      showCoordinador: false,
      showJefeSecre: false,
      showCertificados: false,
    };
  },
  methods: {
    updateButtons() {
      const persona = JSON.parse(localStorage.getItem("persona"));
      this.showAdministrador = persona && persona.Rol === 1;
      this.showCoordinador = persona && persona.Rol === 2;
      this.showJefeSecre = persona && (persona.Rol === 3 || persona.Rol === 4);
    },
  },
  beforeRouteUpdate(to, from, next) {
    // Actualiza el estado de los botones antes de actualizar la ruta
    this.updateButtons();
    next();
  },
  created() {
    // Llama a updateButtons cuando se crea el componente
    this.updateButtons();
  },
};
</script>


