import DashboardLayout from "@/pages/Layout/DashboardLayout.vue";

import Dashboard from "@/pages/Dashboard.vue";
import UserProfile from "@/pages/UserProfile.vue";
import TableList from "@/pages/TableList.vue";
import Typography from "@/pages/Typography.vue";
import Icons from "@/pages/Icons.vue";
import Maps from "@/pages/Maps.vue";
import Notifications from "@/pages/Notifications.vue";
import UpgradeToPRO from "@/pages/UpgradeToPRO.vue";

import Login from "@/pages/Login/Login.vue";

import MenuInscripcion from "@/pages/MenuInscripcion/MenuInscripcionPage.vue";
import Repositorios from "@/pages/Repositorios/RepositoriosPage.vue";

import Rol from "@/pages/Rol/RolPage.vue";
import UnidadAcademica from "@/pages/UnidadAcademica/UnidadAcademicaPage.vue";
import Persona from "@/pages/Persona/PersonaPage.vue";
import Carrera from "@/pages/Carrera/CarreraPage.vue";
import Evaluador from "@/pages/Evaluador/EvaluadorPage.vue";
import Institucion from "@/pages/Institucion/InstitucionPage.vue";
import Alumno from "@/pages/Alumno/AlumnoPage.vue";
import Idioma from "../pages/Idioma/IdiomaPage.vue";

import CreateInst from "@/pages/CreateInst/CreateInstPage.vue";
import CreateProg from "@/pages/CreateProg/CreateProgPage.vue";
import CreateDiag from "@/pages/CreateDiag/CreateDiagPage.vue";
import CreateIDiag from "@/pages/CreateIDiag/CreateIDiagPage.vue";
import CreateSuple from "@/pages/CreateSuple/CreateSuplePage.vue";
import CreateConv from "@/pages/CreateConv/CreateConvPage.vue";

import NewEx from "@/pages/NewEx/NewExPage.vue";

const routes = [
  {
    path: "/",
    component: DashboardLayout,
    // redirect: "/dashboard",
    redirect: "/login",
    children: [
      {
        path: "dashboard",
        name: "Inicio",
        component: Dashboard,
      },
      {
        path: "user",
        name: "Perfil de Usuario",
        component: UserProfile,
      },
      {
        path: "table",
        name: "Table List",
        component: TableList,
      },
      {
        path: "typography",
        name: "Typography",
        component: Typography,
      },
      {
        path: "icons",
        name: "Icons",
        component: Icons,
      },
      {
        path: "maps",
        name: "Maps",
        meta: {
          hideFooter: true,
        },
        component: Maps,
      },
      {
        path: "notifications",
        name: "Notifications",
        component: Notifications,
      },
      {
        path: "upgrade",
        name: "Upgrade to PRO",
        component: UpgradeToPRO,
      },

      {
        path: "login",
        name: "Login",
        meta: {
          hideFooter: true,
          sinmenu: true,
          sinheader: true,
          login: true,
        },
        component: Login,
      },

      {
        path: "inscripcion",
        name: "Menu Inscripcion",
        component: MenuInscripcion,
      },

      {
        path: "repositorios",
        name: "Repositorios",
        component: Repositorios,
      },

      {
        path: "rol",
        name: "Rol",
        component: Rol,
      },
      {
        path: "unidadacademica",
        name: "Unidades Academicas",
        component: UnidadAcademica,
      },
      {
        path: "persona",
        name: "Usuarios",
        component: Persona,
      },
      {
        path: "carrera",
        name: "Carreras",
        component: Carrera,
      },
      {
        path: "evaluador",
        name: "Evaluadores",
        component: Evaluador,
      },
      {
        path: "institucion",
        name: "Instituciones",
        component: Institucion,
      },
      {
        path: "alumno",
        name: "Certificados Emitidos",
        component: Alumno,
      },
      {
        path: "createinst",
        name: "Crear Certificado de Convenio",
        component: CreateInst,
      },
      {
        path: "createprog",
        name: "Crear Certificado de Programa",
        component: CreateProg,
      },
      {
        path: "creatediag",
        name: "Crear Certificado de Diagnostico",
        component: CreateDiag,
      },
      {
        path: "createidiag",
        name: "Crear Certificado de Diagnostico Convenio",
        component: CreateIDiag,
      },
      {
        path: "createsuple",
        name: "Crear Certificado de Supletorio",
        component: CreateSuple,
      },
      {
        path: "createconv",
        name: "Crear Planilla de revalidacion",
        component: CreateConv,
      },
      {
        path: "newex",
        name: "Exportar listas certificacion",
        component: NewEx,
      },
      {
        path: "idioma",
        name: "Idiomas",
        component: Idioma,
      },
    ],
  },
];

export default routes;
