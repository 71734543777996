import MainService from "@/services/MainService.js";
import dataTable from "@/components/Datatable";
// import Loader from "@/components/Loader";
window.$ = window.jQuery = require("jquery");

export default {
  name: "CarreraPage",
  data() {
    let ss = new MainService();
    return {
      msg: "CarreraPage",
      ss: ss,
      ajax: {
        url: ss.indexCarrera(),
        headers: ss.getToken(),
      },
      columns: [
        {
          data: "id",
          name: "id",
          orderable: false,
          searchable: false,
          visible: false,
        },
        {
          data: "DT_RowIndex",
          name: "DT_RowIndex",
          title: "N",
          orderable: false,
          searchable: false,
        },
        { data: "Carrera", name: "Carrera", title: "Carrera" },
        // { data: 'UnidadAcademica', name: 'UnidadAcademica', title: 'Unidad Academica' },
        {
          data: "action",
          orderable: false,
          title: "Acciones",
          searchable: false,
        },
      ],
      carreras: [],
      carrera: {},
      isLoading: false,
      errorBag: {},
    };
  },
  methods: {
    newCarrera() {
      this.errorBag = {};
      this.carrera = {};
      this.$refs["frm-carrera"].show();
    },
    showCarrera(id) {
      this.isLoading = true;
      this.ss
        .showCarrera(id)
        .then((result) => {
          let response = result.data;
          this.carrera = response.data;
          this.$refs["view-carrera"].show();
          this.isLoading = false;
        })
        .catch((error) => {
          console.log(error);
          this.isLoading = false;
        });
    },
    editCarrera() {
      this.errorBag = {};
      this.$refs["frm-carrera"].show();
      this.$refs["view-carrera"].hide();
    },
    cancelCarrera() {
      if (this.carrera.id) {
        this.$refs["view-carrera"].show();
      }
      this.$refs["frm-carrera"].hide();
    },
    saveCarrera() {
      this.ss
        .storeCarrera(this.carrera)
        .then((result) => {
          //console.log(result);
          let response = result.data;
          this.$bvToast.toast(response.msg, {
            title: "Correcto",
            variant: "success",
            autoHideDelay: 5000,
          });
          this.$refs["frm-carrera"].hide();
          this.$refs["datatable-carrera"].reload();
        })
        .catch((error) => {
          this.errorBag = error.response.data.errors;
          this.$bvToast.toast(
            "Problema al Guardar el Registro, favor verificar los Datos",
            {
              title: "Error",
              variant: "danger",
              autoHideDelay: 5000,
            }
          );
        });
    },
    deleteCarrera() {
      this.$swal({
        title: "¿Estás seguro que deseas eliminar?",
        text: "Esta acción es irreversible!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sí, eliminar",
        cancelButtonText: "Cancelar",
      }).then((result) => {
        if (result.isConfirmed) {
          this.ss
            .destroyCarrera(this.carrera)
            .then((result) => {
              let response = result.data;
              //console.log(response);
              this.$swal({
                title: "¡Eliminado!",
                text: "La carrera ha sido eliminada.",
                icon: "success",
              });
              this.$refs["view-carrera"].hide();
              this.$refs["datatable-carrera"].reload();
            })
            .catch((error) => {
              console.log(error);
            });
        }
      });
    },

    draw() {
      window.$(".btn-datatable-Carrera").on("click", (evt) => {
        const data = window.$(evt.target)[0].id;
        this.showCarrera(data);

        // const data = window.$(evt.currentTarget).data('id');
        // this.showCarrera(data);
      });
    },
  },
  components: {
    dataTable,
    // Loader
  },
  mounted() {
    var persona = JSON.parse(localStorage.getItem("persona"));
    if (!persona) {
      this.$router.push("/Login");
    }
  },
};
