import MainService from "@/services/MainService.js";
import dataTable from "@/components/Datatable";
// import Loader from "@/components/Loader";
window.$ = window.jQuery = require("jquery");

export default {
  name: "PersonaPage",
  data() {
    let ss = new MainService();
    return {
      msg: "PersonaPage",
      ss: ss,
      ajax: {
        url: ss.indexPersona(),
        headers: ss.getToken(),
      },
      columns: [
        {
          data: "id",
          name: "id",
          orderable: false,
          searchable: false,
          visible: false,
        },
        {
          data: "DT_RowIndex",
          name: "DT_RowIndex",
          title: "N",
          orderable: false,
          searchable: false,
        },
        {
          data: "ApellidoPaterno",
          name: "ApellidoPaterno",
          title: "Apellido Paterno",
        },
        {
          data: "ApellidoMaterno",
          name: "ApellidoMaterno",
          title: "Apellido Materno",
        },
        { data: "Nombres", name: "Nombres", title: "Nombres" },
        { data: "Rol", name: "Rol", title: "Rol", searchable: false },
        {
          data: "Alias",
          name: "Alias",
          title: "Unidad Academica",
          searchable: false,
        },
        {
          data: "Carrera",
          name: "Carrera",
          title: "Carrera",
          searchable: false,
        },
        { data: "email", name: "email", title: "Email" },
        {
          data: "action",
          orderable: false,
          title: "Acciones",
          searchable: false,
        },
      ],
      personas: [],
      persona: {
        unidadacademica: "",
        rol: "",
        Grado: "",
        password: "",
        selectedCarreras: [],
      },
      isLoading: false,
      errorBag: {},
      roles: null,
      unidadesacademicas: null,
      coordinadores: null,
      Auth: JSON.parse(localStorage.getItem("persona")),
    };
  },
  methods: {
    newPersona() {
      //console.log(this.Auth);
      this.errorBag = {};
      this.persona = {
        unidadacademica: "",
        rol: "",
        Grado: "",
        password: "",
        selectedCarreras: [],
      };
      this.isLoading = true;
      this.ss
        .listPersona()
        .then((result) => {
          let response = result.data;
          this.roles = response.roles;
          this.unidadesacademicas = response.unidadesacademicas;
          this.carreras = response.carreras;
          //console.log(response);
          this.$refs["frm-persona"].show();
          this.isLoading = false;
        })
        .catch((error) => {
          console.log(error);
          this.isLoading = false;
        });
    },
    showPersona(id) {
      this.isLoading = true;
      this.ss
        .showPersona(id)
        .then((result) => {
          let response = result.data;
          this.roles = response.roles;
          this.unidadesacademicas = response.unidadesacademicas;
          this.carreras = response.carreras;
          this.persona = response.data;
          console.log(response);
          this.$refs["view-persona"].show();
          this.isLoading = false;
        })
        .catch((error) => {
          console.log(error);
          this.isLoading = false;
        });
    },
    editPersona() {
      this.errorBag = {};
      this.$refs["frm-persona"].show();
      this.$refs["view-persona"].hide();
    },
    cancelPersona() {
      if (this.persona.id) {
        this.$refs["view-persona"].show();
      }
      this.$refs["frm-persona"].hide();
    },
    savePersona() {
      if (!this.persona.UnidadAcademica) {
        this.persona.unidadacademica = this.Auth.UnidadAcademica;
        this.persona.UnidadAcademica = this.Auth.UnidadAcademica;
      } else {
        this.persona.unidadacademica = this.persona.UnidadAcademica;
      }

      //console.log(this.persona);
      this.ss
        .storePersona(this.persona)
        .then((result) => {
          //console.log(result);
          let response = result.data;
          this.$bvToast.toast(response.msg, {
            title: "Correcto",
            variant: "success",
            autoHideDelay: 5000,
          });
          //console.log(response);
          this.$refs["frm-persona"].hide();
          this.$refs["datatable-persona"].reload();
        })
        .catch((error) => {
          this.errorBag = error.response.data.errors;
          this.$bvToast.toast(
            "Problema al Guardar el Registro, favor verificar los Datos",
            {
              title: "Error",
              variant: "danger",
              autoHideDelay: 5000,
            }
          );
        });
    },
    deletePersona() {
      this.$swal({
        title: "¿Estás seguro que deseas eliminar?",
        text: "Esta acción es irreversible!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sí, eliminar",
        cancelButtonText: "Cancelar",
      }).then((result) => {
        if (result.isConfirmed) {
          this.ss
            .destroyPersona(this.persona)
            .then((result) => {
              let response = result.data;
              //console.log(response);
              this.$swal({
                title: "¡Eliminado!",
                text: "El usuario ha sido eliminado.",
                icon: "success",
              });
              this.$refs["view-persona"].hide();
              this.$refs["datatable-persona"].reload();
            })
            .catch((error) => {
              console.log(error);
            });
        }
      });
    },
    draw() {
      window.$(".btn-datatable-Persona").on("click", (evt) => {
        const data = window.$(evt.target)[0].id;
        this.showPersona(data);
      });
    },
  },
  components: {
    dataTable,
    // Loader
  },
  mounted() {
    var persona = JSON.parse(localStorage.getItem("persona"));
    if (!persona) {
      this.$router.push("/Login");
    }
  },
};
