import MainService from "@/services/MainService.js";
import dataTable from "@/components/Datatable";
// import Loader from "@/components/Loader";
window.$ = window.jQuery = require("jquery");

export default {
  name: "InstitucionPage",
  data() {
    let ss = new MainService();
    return {
      msg: "InstitucionPage",
      ss: ss,
      ajax: {
        url: ss.indexInstitucion(),
        headers: ss.getToken(),
      },
      columns: [
        {
          data: "id",
          name: "id",
          orderable: false,
          searchable: false,
          visible: false,
        },
        {
          data: "DT_RowIndex",
          name: "DT_RowIndex",
          title: "N",
          orderable: false,
          searchable: false,
        },
        { data: "Institucion", name: "Institucion", title: "Institución" },
        { data: "Responsable", name: "Responsable", title: "Responsable" },
        { data: "Correo", name: "Correo", title: "Correo" },
        { data: "Celular", name: "Celular", title: "Celular" },
        { data: "Direccion", name: "Direccion", title: "Dirección" },
        // { data: 'UnidadAcademica', name: 'UnidadAcademica', title: 'Unidad Académica' },
        {
          data: "action",
          orderable: false,
          title: "Acciones",
          searchable: false,
        },
      ],
      instituciones: [],
      institucion: {},
      isLoading: false,
      errorBag: {},
    };
  },
  methods: {
    newInstitucion() {
      this.errorBag = {};
      this.institucion = {};
      this.$refs["frm-institucion"].show();
    },
    showInstitucion(id) {
      this.isLoading = true;
      this.ss
        .showInstitucion(id)
        .then((result) => {
          let response = result.data;
          this.institucion = response.data;
          this.$refs["view-institucion"].show();
          this.isLoading = false;
        })
        .catch((error) => {
          console.log(error);
          this.isLoading = false;
        });
    },
    editInstitucion() {
      this.errorBag = {};
      this.$refs["frm-institucion"].show();
      this.$refs["view-institucion"].hide();
    },
    cancelInstitucion() {
      if (this.institucion.id) {
        this.$refs["view-institucion"].show();
      }
      this.$refs["frm-institucion"].hide();
    },
    saveInstitucion() {
      this.ss
        .storeInstitucion(this.institucion)
        .then((result) => {
          //console.log(result);
          let response = result.data;
          this.$bvToast.toast(response.msg, {
            title: "Correcto",
            variant: "success",
            autoHideDelay: 5000,
          });
          //console.log(response);
          this.$refs["frm-institucion"].hide();
          this.$refs["datatable-institucion"].reload();
        })
        .catch((error) => {
          this.errorBag = error.response.data.errors;
          this.$bvToast.toast(
            "Problema al Guardar el Registro, favor verificar los Datos",
            {
              title: "Error",
              variant: "danger",
              autoHideDelay: 5000,
            }
          );
        });
    },
    deleteInstitucion() {
      this.$swal({
        title: "¿Estás seguro que deseas eliminar?",
        text: "Esta acción es irreversible!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sí, eliminar",
        cancelButtonText: "Cancelar",
      }).then((result) => {
        if (result.isConfirmed) {
          this.ss
            .destroyInstitucion(this.institucion)
            .then((result) => {
              let response = result.data;
              //console.log(response);
              this.$swal({
                title: "¡Eliminado!",
                text: "La institucion ha sido eliminada.",
                icon: "success",
              });
              this.$refs["view-institucion"].hide();
              this.$refs["datatable-institucion"].reload();
            })
            .catch((error) => {
              console.log(error);
            });
        }
      });
    },
    draw() {
      window.$(".btn-datatable-Institucion").on("click", (evt) => {
        const data = window.$(evt.target)[0].id;
        this.showInstitucion(data);
      });
    },
  },
  components: {
    dataTable,
    // Loader
  },
  mounted() {
    var persona = JSON.parse(localStorage.getItem("persona"));
    if (!persona) {
      this.$router.push("/Login");
    }
  },
};
